import { defineStore } from 'pinia'
import { DownloadClientInstance } from '../download-client/dl-client'
import { UserResponse } from '../download-client/download-client'
import { configureGlobalHeaderWidgetSession } from '../helpers/widget'

export const useUserStore = defineStore('user', {
  state: (): {userInfo: UserResponse | null, userInfoError: boolean} => ({
    userInfo: null,
    userInfoError: false
  }),
  getters: {
    getUserInfo (state) {
      return state.userInfo
    },
    getUserInfoError (state) {
      return state.userInfoError
    }
  },
  actions: {
    resetUserInfo () {
      this.userInfo = null
      this.userInfoError = false
    },
    async gettingUserInfo () {
      try {
        this.userInfoError = false
        this.userInfo = await DownloadClientInstance.auth_UserInfo()
        return true
      } catch (error: any) {
        // when not authenticated configure global header widget with correct session active state (loggedon)
        // only configure header when auth session changed from active -> inactive
        if (error?.status === 401) {
          const userWasLoggedOn = !!this.userInfo
          if (userWasLoggedOn) {
            configureGlobalHeaderWidgetSession(false)
          }
        } else if (error?.status === 500) {
          this.userInfoError = true
        }
        this.userInfo = null
        return false
      }
    }
  }
})
