import { cookieKey } from '@/infrastructure/adapters/constants'
import { defineComponent, onMounted, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-cookie-modal',
  setup () {
    const cookieModalOpen = ref(false)
    const modalKey = ref(0)

    const toggle = () => {
      alert('toggle')
    }

    onMounted(() => {
      if (localStorage.getItem(cookieKey) === null) {
        cookieModalOpen.value = true
        modalKey.value += 1
      }
    })

    return {
      cookieModalOpen,
      modalKey,
      toggle
    }
  }
})
