import Vue from 'vue'
import VueGtagPlugin from 'vue-gtag'
import { Environment, GtagId } from '../adapters/environment-variables'

export default {
  install (vue:typeof Vue):void {
    vue.use(VueGtagPlugin, {
      config: {
        id: GtagId,
        params: {
          anonymize_ip: true,
          send_page_view: true
        },
        appName: `Download-${Environment}`,
        pageTrackerScreenviewEnabled: true
      }
    })
  }
}
