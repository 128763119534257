import { IRouteConfig } from '@/infrastructure/router/interfaces'
import { RouteProductDetail } from '@/modules/product-detail-module/routes'

const ProductConfigModule = () => import('../product-config-module.vue')

export const RouteProductConfig: IRouteConfig = {
  path: '/product/:id/configureer',
  name: 'Config',
  component: ProductConfigModule,
  meta: {
    title: 'Product Configuratie',
    breadcrumb: {
      parent: RouteProductDetail
    },
    tags: [
      {
        key: 'twitter:card',
        attribute: 'name',
        content: 'summary_large_image'
      }
    ]
  }
}

export const ProductConfigRoutes = [RouteProductConfig]

export default ProductConfigRoutes
