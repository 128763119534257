import _Vue from 'vue'

import DlContainer from '@/components/dl-container/dl-container.vue'
import DlFormField from '@/components/dl-form-field/dl-form-field.vue'
import DlPeriod from '@/components/dl-period/dl-period.vue'
import DlBreadcrumb from '@/components/dl-breadcrumb/dl-breadcrumb.vue'
import DlVersionHeader from '@/components/dl-version-header/dl-version-header.vue'
import DlHeader from '@/components/dl-header/dl-header.vue'
import DlDoormat from '@/components/dl-doormat/dl-doormat.vue'
import DlAlertPage from '@/components/dl-alert-page/dl-alert-page.vue'
import DlCookieModal from '@/components/dl-cookie-modal/dl-cookie-modal.vue'
import DlCookieConsent from '@/components/dl-cookie-consent/dl-cookie-consent.vue'
import DlHrefInterceptor from '@/components/dl-href-interceptor/dl-href-interceptor.vue'
import DlFolderNodeComponent from '@/components/dl-folder-node-component/dl-folder-node-component.vue'
import DlFolderAccordionComponent from '@/components/dl-folder-accordion-component/dl-folder-accordion-component.vue'

export default {
  install (Vue: typeof _Vue): void {
    Vue.component('dl-container', DlContainer)
    Vue.component('dl-form-field', DlFormField)
    Vue.component('dl-period', DlPeriod)
    Vue.component('dl-breadcrumb', DlBreadcrumb)
    Vue.component('dl-version-header', DlVersionHeader)
    Vue.component('dl-header', DlHeader)
    Vue.component('dl-doormat', DlDoormat)
    Vue.component('dl-alert-page', DlAlertPage)
    Vue.component('dl-cookie-modal', DlCookieModal)
    Vue.component('dl-cookie-consent', DlCookieConsent)
    Vue.component('dl-href-interceptor', DlHrefInterceptor)
    Vue.component('dl-folder-node-component', DlFolderNodeComponent)
    Vue.component('dl-folder-accordion-component', DlFolderAccordionComponent)
  }
}
