import { Ref, watch } from '@vue/composition-api'
import { RouterInstance } from '../router'

export class UrlParams {
  paramKeys: string[] = [];

  addParam (
    param: unknown,
    key: string,
    parser: ((queryKeyVal: string | (string | null)[]) => unknown) | null = null
  ) {
    if (RouterInstance.router) {
      watch(
        RouterInstance.data,
        () => {
          if (param && typeof param === 'object') {
            if (assertIsRef(param)) {
              if (RouterInstance.data.value.query[key]) {
                param.value = parseQueryValue(
                  param.value,
                  RouterInstance.data.value.query[key]
                )
              }
            } else if (Object.keys(param).length > 0) {
              const queryKeys = Object.keys(
                RouterInstance.data.value.query
              ).filter((queryKey) => queryKey.startsWith(`${key}_`))
              queryKeys.forEach((queryKey) => {
                const queryKeyVal = RouterInstance.data.value.query[queryKey]
                const splitQueryKey = queryKey.split('_')
                if (splitQueryKey.length > 0) {
                  const parsedQueryKey = splitQueryKey[1]
                  if (parsedQueryKey in param) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (param as any)[parsedQueryKey] = parser
                      ? parser(queryKeyVal)
                      : queryKeyVal
                  }
                }
              })
            }
          }
        },
        { deep: true, immediate: true }
      )
    }

    this.paramKeys.push(key)

    if (param && typeof param === 'object') {
      watch(
        param,
        () => {
          let query = { ...RouterInstance.router?.currentRoute.query }
          if (assertIsRef(param)) {
            query = { ...query, [key]: param.value }
          } else if (Object.keys(param).length > 0) {
            const objectList = Object.keys(param).reduce(
              (prev, curr) => ({
                ...prev,
                [`${key}_${curr}`]: (param as never)[curr]
              }),
              {}
            )
            query = { ...query, ...objectList }
          }
          RouterInstance.router?.replace({ query }).catch(() => {
            /**/
          })
        },
        { deep: true }
      )
    }
  }
}

const parseQueryValue = (
  param: unknown,
  queryValue: string | (string | null)[]
) => {
  if (Array.isArray(param) && !Array.isArray(queryValue)) {
    return [queryValue]
  } else if (typeof queryValue === 'string' && !isNaN(Number(queryValue))) {
    return Number(queryValue)
  } else if (
    typeof queryValue === 'string' &&
    (queryValue.toLowerCase() === 'true' ||
      queryValue.toLowerCase() === 'false')
  ) {
    return queryValue.toLowerCase() === 'true'
  } else if (Array.isArray(queryValue)) {
    return (queryValue as string[]).map(value => parseUrlParamValue(value, true))
  } else {
    return parseUrlParamValue(decodeURI(queryValue as string), true)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const assertIsRef = (param: unknown): param is Ref =>
  typeof param === 'object' && 'value' in (param as any)

export const parseUrlParamValue = (value:string, isQueryParam: boolean) => {
  if (!isQueryParam) {
    value.replaceAll('&', '')
  }
  return value.toLowerCase()
    .replaceAll(' ', '-')
    .replaceAll(/-+/g, '-')
    .replaceAll('.', '')
    .replaceAll(',', '')
    .replaceAll('/', '')
    .replaceAll(/[^a-zA-Z0-9-ïáéíóúýçäëïöüÿâêîôûãõñ&@+{}()[_,]/g, '')
}
