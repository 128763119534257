import { IRouteConfig } from '@/infrastructure/router/interfaces'
import { RouteCatalogus } from '@/modules/catalogus-module/routes'

const DatasetDetailComponent = () =>
  import('../product-detail-module.vue')

export const RouteProduct = {
  path: '/product',
  redirect: '/catalogus'
}

export const RouteProductDetail: IRouteConfig = {
  path: '/product/:id*',
  name: 'Product',
  component: DatasetDetailComponent,
  meta: {
    title: 'Product detail',
    breadcrumb: {
      parent: RouteCatalogus
    },
    tags: [
      {
        key: 'twitter:card',
        attribute: 'name',
        content: 'summary_large_image'
      }
    ]
  }
}

export const ProductDetailRoutes = [RouteProductDetail, RouteProduct]

export default ProductDetailRoutes
