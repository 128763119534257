import Vue from 'vue'

export default Vue.extend({
  name: 'dl-container',
  data () {
    return {

    }
  },
  components: {

  },
  computed: {},
  methods: {},
  props: {}
})
