import 'reflect-metadata'
import { EnumAlertType, useAlertStore } from '@/infrastructure/store/alert'
import { computed, defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import * as widgetClient from '@govflanders/vl-widget-client'
import { GenerateErrorToaster } from '@/infrastructure/alert-handler/alert-toaster'
import { DefaultLogoutUrl, DefaultLoginUrl, cookieLinkIdentifier, SwitchIdentityUrl } from '@/infrastructure/adapters/environment-variables'
import { useUserStore } from '@/infrastructure/store/user'
import { UserResponse } from '@/infrastructure/download-client/download-client'
import { RouteLoginRedirect } from './login-redirect-module/routes'

export default defineComponent({
  name: 'base-module',
  setup: () => {
    const alertStore = useAlertStore()
    const removeAlert = (id:string) => {
      alertStore.removeAlert(id)
    }
    const userStore = useUserStore()
    const userInfo: Ref<UserResponse | null> = ref(null)

    const widgetCapture = () => {
      widgetClient.capture((widget) => {
        widget.getExtension('citizen_profile.session').then((session) => {
          // Capture activity logging
          function activityEventHandler () {
            (session as any).extend()
          }
          // Register our event handler given event name.
          window.addEventListener('mousedown', activityEventHandler)

          return (session as any).configure({
            active: !!userStore.getUserInfo,
            endpoints: {
              loginUrl: DefaultLoginUrl,
              logoutUrl: DefaultLogoutUrl,
              loginRedirectUrl: RouteLoginRedirect.path,
              switchCapacityUrl: SwitchIdentityUrl
            }
          })
        })

        // Get the Citizen Profile Session extension from the global header widget.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        widget.on('citizen_profile.session.logout.request', (logoutRequest: any) => {
          // Acknowledge the logout request to prevent the session extension from performing default
          // action due to response timeout (5 seconds).
          logoutRequest.acknowledge()
          const reason = logoutRequest.getRequest().getReason()

          // reset user session data
          userStore.resetUserInfo()

          // Evaluate the type of logout request.
          switch (reason) {
            // Logout was requested because the citizen profile extension has detected an expired
            // session which prevents the user from accessing citizen profile without a step-up.
            // This could be the result of an application logout or external logout.
            case 'expired':
              logoutRequest.accept()
              break

            // Logout was requested by the user. This request should never be rejected in normal
            // circumstances.
            case 'user':
              // Accept the request for website logout.
              logoutRequest.accept()
              break

            // Logout was requested as the citizen profile extension has detected inactivity from
            // the user.
            case 'inactivity':
              // Accept the request for website logout.
              logoutRequest.accept()
              break

            default:
              // Reject the request for website logout.
              logoutRequest.reject()
              break
          }
        })
      }, (widget) => widget.getPluginTypeId() === 'global_header')
    }

    const initGlobalHeader = async () => {
      try {
        const header = await widgetClient.bootstrap(process.env.VUE_APP_GLOBAL_HEADER as string)
        header.setMountElement(document.getElementById('global-header-placeholder'))
        header.mount()
      } catch (error) {
        GenerateErrorToaster(error)
      }
    }

    const initGlobalFooter = async () => {
      try {
        const footer = await widgetClient.bootstrap(process.env.VUE_APP_GLOBAL_FOOTER as string)
        footer.setMountElement(document.getElementById('global-footer-placeholder'))
        footer.mount()
      } catch (error) {
        GenerateErrorToaster(error)
      }
    }

    const alertTypeAttribute = (alertType: EnumAlertType | undefined) => {
      switch (alertType) {
        case EnumAlertType.Error:
          return 'mod-error'
        case EnumAlertType.Success:
          return 'mod-success'
        case EnumAlertType.Warning:
          return 'mod-warning'
        default:
          return 'mod-info'
      }
    }

    onMounted(() => {
      widgetCapture()
      initGlobalFooter()

      userStore.gettingUserInfo().then(() => {
        initGlobalHeader()
        userInfo.value = userStore.getUserInfo

        if (userStore.getUserInfoError === true) {
          GenerateErrorToaster({
            title: 'Probleem met aanmelden',
            detail: 'Er is een probleem opgetreden bij het aanmelden. Probeer later opnieuw.'
          })
        }
      })
    })

    return {
      alertStore,
      alertTypeAttribute,
      removeAlert,
      userInfo,
      cookieLinkIdentifier
    }
  }
})
