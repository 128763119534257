import { defineComponent, onMounted, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-href-interceptor',
  props: {
    linkIdentifier: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const set = ref(new Set<Element>())

    function clickHandler (event:any) {
      event.preventDefault()
      emit('intercept')
    }

    function initObserver () {
      const observer = new MutationObserver(() => {
        set.value.forEach((el:Element) => {
          el.removeEventListener('click', clickHandler)
        })
        set.value = new Set(document.querySelectorAll(`[href='${props.linkIdentifier}']`))
        set.value.forEach((el:Element) => {
          el.addEventListener('click', clickHandler)
        })
      })
      observer.observe(document.body, {
        attributes: true,
        subtree: true
      })
    }

    onMounted(() => {
      initObserver()
    })
  }
})
