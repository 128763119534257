import * as widgetClient from '@govflanders/vl-widget-client'
import { RouteLoginRedirect } from '../../modules/login-redirect-module/routes'
import { DefaultLoginUrl, DefaultLogoutUrl, SwitchIdentityUrl } from '../adapters/environment-variables'

export const configureGlobalHeaderWidgetSession = (active: boolean) => {
  widgetClient.capture((widget) => {
    // register session extension
    widget.getExtension('citizen_profile.session').then((session) => {
      return (session as any).configure({
        active: active,
        endpoints: {
          loginUrl: DefaultLoginUrl,
          logoutUrl: DefaultLogoutUrl,
          loginRedirectUrl: RouteLoginRedirect.path,
          switchCapacityUrl: SwitchIdentityUrl
        }
      })
    })
  }, (widget) => widget.getPluginTypeId() === 'global_header')
}
