import { defineComponent } from '@vue/composition-api'
import { RouteHome } from '../home-module/routes'

export default defineComponent({
  setup () {
    const error = {
      title: 'De inhoud van deze pagina is niet beschikbaar',
      message: 'Mogelijk is deze verplaatst of verwijderd.'
    }
    const redirectRoute = RouteHome

    return { error, redirectRoute }
  }
})
