import { ErrorToAlert } from '@/infrastructure/alert-handler/error-parser'
import { DlLogger } from '@/infrastructure/dl-logger'
import { LogLevel } from '@/infrastructure/download-client/download-client'
import { RouterInstance } from '@/infrastructure/router'
import { IRouteConfig } from '@/infrastructure/router/interfaces'
import { EnumAlertType } from '@/infrastructure/store/alert'
import { computed, defineComponent, getCurrentInstance, PropType, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-alert-page',
  props: {
    fetching: { type: Boolean, default: true },
    error: Object,
    modRetry: { type: Boolean, default: false },
    modRedirectMessage: { type: String, required: false },
    redirect: { type: Object as PropType<IRouteConfig>, required: false }
  },
  setup (props, { emit }) {
    const instance = getCurrentInstance()
    const reload = () => {
      RouterInstance.router?.go(0)
    }

    const paredError = computed(() => {
      if (props.error) {
        return ErrorToAlert(props.error)
      }
      return null
    })

    const alertTypeAttribute = (alertType: EnumAlertType | undefined) => {
      switch (alertType) {
        case EnumAlertType.Error:
          return 'mod-error'
        case EnumAlertType.Success:
          return 'mod-success'
        case EnumAlertType.Warning:
          return 'mod-warning'
        default:
          return 'mod-info'
      }
    }

    const redirectMessage = computed(() => {
      return props.modRedirectMessage ? props.modRedirectMessage : `Ga naar ${props.redirect?.name}`
    })

    const redirectTo = () => {
      if (props.redirect) {
        RouterInstance.router?.push(props.redirect)
      }
    }

    watch(() => props.fetching, () => {
      emit('fetching', props.fetching)
    })
    watch(() => props.error, () => {
      if (props.error) {
        DlLogger(LogLevel.Error, instance?.parent?.type.name as string || '', null, props.error)
      }
      emit('has-error', !!props.error)
    })

    return {
      alertTypeAttribute,
      paredError,
      redirectMessage,
      redirectTo,
      reload
    }
  }
})
