import Vue from 'vue'
import App from '@/modules/app.vue'
import {
  routes,
  defaultGuards,
  initRouter,
  RouterInstance
} from './infrastructure/router'
import { createPinia } from 'pinia'
import { loadVueLibs } from './infrastructure/setup/vue-libs'

RouterInstance.router = initRouter({
  guards: defaultGuards,
  routes
})

loadVueLibs(Vue)

new Vue({
  router: RouterInstance.router,
  render: (h) => h(App),
  pinia: createPinia()
}).$mount('#app')
