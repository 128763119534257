import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-form-field',
  props: {
    for: String,
    name: String,
    label: String,
    rules: Array as PropType<string[]>,
    modHiddenLabel: Boolean,
    modRequired: Boolean,
    labelBefore: Boolean
  },
  setup (props) {
    const parsedLabel = computed(
      () =>
        `${props.label ? props.label : '&nbsp;'}${
          props.modRequired ? '&nbsp;<span class="dl-form-field-error">*</span>' : ''
        }`
    )
    const vlFormValidation: any = ref(null)
    const forVal = computed(() => props.for)
    const parsedRules = computed(() => {
      let rules: string[] = []
      if (props.modRequired) {
        rules.push('required')
      }
      if (props.rules) {
        rules = [...rules, ...props.rules]
      }
      return rules.join('|')
    })

    return { parsedLabel, forVal, parsedRules, vlFormValidation }
  }
})
