import { Environment } from '@/infrastructure/adapters/environment-variables'
import { DownloadClientInstance } from '@/infrastructure/download-client/dl-client'
import { computed, ComputedRef, defineComponent, onMounted, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-version-header',
  setup () {
    const headerMessage: ComputedRef<{color:string, message:string}| null> = computed(() => {
      const buildMessage =
      Environment + (BUILD_VERSION ? ` ${BUILD_VERSION}` : '') + (apiMessage.value ? ` | API ${apiMessage.value}` : '')
      switch (Environment) {
        case 'test':
          return {
            color: '#ff9800',
            message: buildMessage
          }
        case 'testat':
          return {
            color: '#ff9800',
            message: buildMessage
          }
        case 'staging':
          return {
            color: '#4CAF50',
            message: buildMessage
          }
        case 'beta':
          return {
            color: '#2196F3',
            message: buildMessage
          }
        case 'development':
          return {
            color: '#f44336',
            message: buildMessage
          }
      }
      return null
    })

    const apiMessage = ref('')

    onMounted(() => {
      DownloadClientInstance.apiInfo_ApiInfo().then((response) => {
        apiMessage.value = (response.environment || '') + ' ' + (response.buildTag || '')
      })
    })

    return { headerMessage }
  }
})
