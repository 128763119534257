import { latestLocation } from '@/infrastructure/adapters/constants'
import { RouteLoginRedirect } from '@/modules/login-redirect-module/routes'
import { NavigationGuard } from 'vue-router'
import { IRoute } from '../interfaces'

export const LatestLocationHandler: NavigationGuard = async (
  to:IRoute,
  from:IRoute,
  next
) => {
  if (to.path !== RouteLoginRedirect.path) {
    localStorage.setItem(latestLocation, to.path)
  }
  next()
}
