import { RouteCatalogus } from '@/modules/catalogus-module/routes'
import { RouteDownloads } from '@/modules/downloads-module/routes'
import { RouteProductConfig } from '@/modules/product-detail-module/modules/product-config-module/routes'
import { RouteProductDetail } from '@/modules/product-detail-module/routes'
import { parseUrlParamValue } from '../dl-url-params'
import { IRouteConfig } from './interfaces'

const oldRoutes: IRouteConfig[] = [
  {
    path: '/Catalogus',
    redirect: to => {
      return { path: RouteCatalogus.path, query: { q: to.query.q } }
    }
  },
  {
    path: '/Producten/Detail',
    redirect: to => {
      if (!to.query.title) {
        return { path: RouteProductDetail.path, params: { id: `${to.query.id as string}` }, query: {} }
      }
      return { path: RouteProductDetail.path, params: { id: `${to.query.id as string}-${parseUrlParamValue(to.query.title as string, false)}` }, query: {} }
    }
  },
  { path: '/Bestellingen/Winkelkar', redirect: RouteDownloads },
  { path: '/Bestellingen', redirect: RouteDownloads },
  {
    path: '/Bestel/Configureer/:id/:title',
    redirect: to => {
      return { path: RouteProductConfig.path, params: { id: to.params.id }, query: {} }
    }
  }
]

export default oldRoutes
