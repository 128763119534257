import { computed, defineComponent, PropType } from '@vue/composition-api'

export interface FolderNode{
  [key:string]:unknown
  children:FolderNode[]
}

export default defineComponent({
  name: 'dl-folder-node-component',
  props: {
    rootNode: Object as PropType<FolderNode>
  },
  setup (props, { emit }) {
    const parentNode = computed(() => {
      const sortedNode = props.rootNode
      if (sortedNode) {
        const noChildrenArray = sortedNode?.children.filter(x => x.children.length === 0).sort((a, b) => (a.key as string).localeCompare(b.key as string)) as FolderNode[]
        const folderArray = sortedNode?.children.filter(x => x.children.length > 0).sort((a, b) => (a.key as string).localeCompare(b.key as string)) as FolderNode[]
        sortedNode.children = [...noChildrenArray, ...folderArray]
      }
      return sortedNode
    })

    function getKey (key:any) {
      return `${key as string}-${Math.floor(Math.random() * 1000)}`
    }

    function onCheck (value:string) {
      emit('check', value)
    }

    return { parentNode, onCheck, getKey }
  }
})
