import { ICookieStorage, EnumCookieOptions } from '@/components/dl-cookie-consent/dl-cookie-consent'
import { defineStore } from 'pinia'
import { event, pageview, PageView, purchase } from 'vue-gtag'
import { cookieKey } from '../adapters/constants'

export enum EnumAnalyticsPage{
    Home = 'Home',
    Header = 'Header',
    Catalogus = 'Catalogus',
    Detail = 'Detailpagina',
    Configuration = 'Configuratie',
    Downloads = 'Downloads'
}

export enum EnumAnalyticsHomePageActions{
    Search = 'Home: zoekterm',
    Thema = 'Home: thema',
    PopularProducts = 'Home: populaireste producten',
    NewAndModifiedProducts = 'Home: nieuwe en gewijzigde producten'
}

export enum EnumAnalyticsHeaderPageActions{
    Signin = 'Home: aanmelden',
    MyDownloads = 'Home: mijn downloads'
}

export enum EnumAnalyticsCatalogusPageActions{
    Search = 'Catalogus: zoeken',
    Sort = 'Catalogus: sortering',
    Page = 'Catalogus: page',
    LinkToProduct = 'Catalogus: link naar product'
}

export enum EnumAnalyticsDetailPageActions{
    DownloadPredef ='Detailpagina: download 1 predefined file',
    DownloadConfig = 'Detailpagina: download naar configuratie',
    Thema = 'Detailpagina: thema',
    AllMetadata = 'Detailpagina: bekijk alle metadata',
    Legal = 'Detailpagina: bekijk gebruiksvoorwaarden',
    Document= 'Detailpagina: document link',
    Dataset = 'Detailpagina: dataset link',
    EntitityOpenClose = 'Detailpagina: entiteit openen/sluiten',
    EntityDownload = 'Detailpagina: entiteit downloaden',
    LoginForDownload = 'Detailpagina: login om te downloaden'
}

export enum EnumAnalyticsConfigurationPageActions{
    Entity ='Configuratie: entity',
    Mappart = 'Configuratie: versnijding',
    Selection = 'Configuratie: selectie',
    DrawOnMap = 'Configuratie: teken zone',
    Import = 'Configuratie: import',
    Clip = 'Configuratie: clip',
    ZoomMap = 'Configuratie: kaart zoomen',
    FullscreenMap = 'Configuratie: kaart fullscreen mode',
    SearchAddress = 'Configuratie: zoek op adres',
    Format = 'Configuratie: formaat',
    Version = 'Configuratie: versie',
    PredefinedSelection = 'Configuratie: platte lijst predefined files',
    Download = 'Configuratie: download',
    LoginToDownloadPredefinedFlatList = 'Configuration: login voor download platte lijst predefined files',
    LoginToConfigure = 'Configuration: login om meer de kunnen configureren'
}

export enum EnumAnalyticsDownloadsPageActions{
    Sort= 'Downloads: sortering',
    Product = 'Downloads: link naar product',
    Download = 'Downloads: download',
    Refresh = 'Downloads: refresh',
    Page = 'Downloads: pagina',
    Login= 'Downloads: login'
}

export interface IAnalyticsDownloadItems{
    name?:string;
    productId?:string;
    versnijding?:string;
    type?:string;
}

export interface IAnalyticsDownloadParams{
    orderId:string;
    items:IAnalyticsDownloadItems[];
}

export const useAnalyticsStore = defineStore('analytics', {
  state: ():{ canLogAnalytics:boolean|null} => ({
    canLogAnalytics: null
  }),
  actions: {
    getCanLogAnalytics () {
      if (this.canLogAnalytics === null) {
        if (localStorage.getItem(cookieKey)) {
          const options = JSON.parse(localStorage.getItem(cookieKey) as string) as ICookieStorage[]
          const analytics = options.find((x) => x.key === EnumCookieOptions.Analytics)
          if (analytics && analytics.value) this.canLogAnalytics = analytics?.value as boolean
        }
      }
      return this.canLogAnalytics
    },
    logAnalyticsPageView (pv:PageView) {
      if (this.getCanLogAnalytics()) {
        pageview(pv)
      }
    },
    logAnalyticsEvent (action:string, category:string, params: any = undefined) {
      if (this.getCanLogAnalytics()) event(action, params ? { event_label: action, value: JSON.stringify(params), event_category: category } : { event_category: category })
    },
    logDownload (downloadAction:IAnalyticsDownloadParams) {
      if (this.getCanLogAnalytics()) {
        purchase({
          transaction_id: downloadAction.orderId,
          items: downloadAction.items.map((x) => ({
            name: x.name,
            id: x.productId,
            brand: x.type,
            category: x.versnijding
          }))
        })
      }
    }
  }
})
