import { EnumCookieOptions, ICookieStorage } from '@/components/dl-cookie-consent/dl-cookie-consent'
import { cookieKey } from '@/infrastructure/adapters/constants'
import { pageview } from 'vue-gtag'
import { NavigationGuard } from 'vue-router'
import { IRoute } from '../interfaces'

export const GtagHandler: NavigationGuard = async (to:IRoute, from:IRoute, next) => {
  if (localStorage.getItem(cookieKey)) {
    const options = JSON.parse(localStorage.getItem(cookieKey) as string) as ICookieStorage[]
    const analytics = options.find((x) => x.key === EnumCookieOptions.Analytics)
    if (analytics && analytics.value) {
      pageview({
        page_path: decodeURI(to.fullPath as string),
        page_title: to.name as string,
        page_location: decodeURI(window.location.href as string)
      })
    }
  }
  next()
}
