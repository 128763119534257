import { RouterInstance } from '@/infrastructure/router'
import { Ref, computed, defineComponent, ref, watch } from '@vue/composition-api'
import { RawLocation } from 'vue-router'

export default defineComponent({
  name: 'dl-breadcrumb',
  setup: () => {
    const breadcrumbLabel = ref('')
    const parentName = ref('')
    const dynamicPath = ref('')
    const toRoute: Ref<RawLocation> = computed(() => dynamicPath.value !== ''
      ? {
        name: parentName.value,
        params: { id: dynamicPath.value }
      } as RawLocation
      : { name: parentName.value } as RawLocation)

    watch(
      RouterInstance.data.value,
      () => {
        dynamicPath.value = RouterInstance.data.value.meta?.breadcrumb?.dynamicPath || ''
        parentName.value = RouterInstance.data.value.meta?.breadcrumb?.parent?.name || ''
        breadcrumbLabel.value = RouterInstance.data.value.meta?.breadcrumb?.dynamicValue || parentName.value
      },
      { deep: true }
    )

    return { breadcrumbLabel, parentName, toRoute }
  }
})
