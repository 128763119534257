import WKT from 'ol/format/WKT'
import { getArea } from 'ol/sphere'
import VlaanderenWellknown from '../data/VlaanderenWellknown.json'
import * as turf from '@turf/turf'
import wellknown, { GeoJSONPolygon } from 'wellknown'

const ParsedVlaanderenWellknown = (VlaanderenWellknown)

export const surfaceOfWktInKm = (wkt?: string) => {
  if (wkt) {
    const drawnFeature = new WKT().readFeature(wkt)
    const geometry = drawnFeature.getGeometry()
    if (geometry) {
      return getArea(geometry) / 1000000
    }
  }
  return -1
}

export const WkTInsideVlaanderen = (wkt?: string) => {
  if (wkt) {
    const wktParsed = wellknown.parse(wkt)
    if (wktParsed?.type === 'Polygon') {
      const polyDrawn = turf.polygon(wktParsed.coordinates)
      const polyVlaanderen = turf.multiPolygon(ParsedVlaanderenWellknown)
      return !!turf.intersect(polyDrawn, polyVlaanderen)
    }
  }
  return false
}

export const singlePolygonWkt = (wkt?: string) => {
  if (wkt) {
    const wktParsed = wellknown.parse(wkt)
    return wktParsed?.type === 'Polygon'
  }
  return false
}

export const isValidPolygon = (wkt?: string) => {
  if (wkt) {
    const wktParsed = wellknown.parse(wkt)
    if (wktParsed?.type === 'Polygon') {
      try {
        const polyDrawn = turf.polygon(wktParsed.coordinates)
        return (
          polyDrawn.geometry.coordinates.length === 1 && /* Checken op donut */
          turf.kinks(polyDrawn).features.length === 0 /* Checken op snijpunten */
        )
      } catch (error) {
        return false
      }
    }
  }
  return false
}

export const parseWktToPolygon = (wkt?: string) => {
  if (wkt && isValidPolygon(wkt) && singlePolygonWkt(wkt)) {
    return (wellknown.parse(wkt) as GeoJSONPolygon).coordinates[0] as unknown
  }
  return []
}
