import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-doormat-module',
  props: {
    tagName: { type: String, default: 'a' },
    dynamicProperties: { type: Object, default: () => ({}) },
    title: String,
    modAlt: Boolean
  },
  setup (props) {
    const classes = computed(() => [
      'vl-doormat',
      {
        'vl-doormat--alt': props.modAlt
      }
    ])
    return { classes }
  }
})
