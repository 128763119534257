import { DefaultLoginUrl } from '@/infrastructure/adapters/environment-variables'
import { useUserStore } from '@/infrastructure/store/user'
import { NavigationGuard } from 'vue-router'
import { IRoute } from '../interfaces'

export const AuthHandler: NavigationGuard = async (
  to: IRoute,
  from: IRoute,
  next
) => {
  if (to.meta?.authorize) {
    const loggedOn = await useUserStore().gettingUserInfo()
    if (!loggedOn) {
      location.replace(`${DefaultLoginUrl}?returnUrl=${to.path}`)
      return
    }
  }
  next()
}
