import { IRouteConfig } from '@/infrastructure/router/interfaces'
import { RouteHome } from '@/modules/home-module/routes'

const LoginRedirectComponent = () =>
  import('../login-redirect-module.vue')

export const RouteLoginRedirect: IRouteConfig = {
  path: '/loginredirect',
  name: 'Login redirect',
  component: LoginRedirectComponent,
  meta: {
    title: 'Login redirect',
    breadcrumb: {
      parent: RouteHome
    }
  }
}

export const LoginRedirectRoutes = [RouteLoginRedirect]

export default LoginRedirectRoutes
