import vueCompositionApi from '@vue/composition-api'
import { PiniaVuePlugin } from 'pinia'
import { VueConstructor } from 'vue'
import dlComponents from '../dl-components'
/* import ipproComponents from '../ippro-components' */

import * as ipproComponents from '@ippro/be.vlaanderen.ippro.web.vue/'
import webComponents from '../web-components'
import jsonViewer from '../json-viewer'
import gtag from '../gtag'

export const loadVueLibs = (vueInstance: VueConstructor<Vue>) => {
  vueInstance.use(vueCompositionApi)
  vueInstance.use(webComponents)
  vueInstance.use(dlComponents)
  vueInstance.use(ipproComponents)
  vueInstance.use(PiniaVuePlugin)
  vueInstance.use(jsonViewer)
  vueInstance.use(gtag)

  return vueInstance
}
