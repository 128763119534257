import { RootRoutes } from '@/modules/routes'

export const updateMetaWithContent = (
  key: string,
  content?: string,
  attribute = 'name'
) => {
  let metaDescription = document?.querySelector(`meta[${attribute}="${key}"]`)
  if (content) {
    if (!metaDescription) {
      metaDescription = document.createElement('meta')
      document.getElementsByTagName('head')[0].appendChild(metaDescription)
      metaDescription.setAttribute(attribute, key)
    }
    metaDescription.setAttribute('content', content || '')
  } else if (metaDescription) {
    metaDescription.remove()
  }
}

export const resetAllTags = () => {
  updateMetaDescription()
  updateMetaKeywords()
  const metaPropertiesToExclude = [
    'og:type',
    'og:site_name',
    'og:locale',
    'twitter:site'
  ]
  Array.from(
    document?.querySelectorAll('meta[property^="og:"]')
  ).filter(
    (ogMeta) =>
      !metaPropertiesToExclude.includes(
        Array.from(ogMeta.attributes).find(
          (attribute) => attribute.name === 'property'
        )?.value || ''
      )
  ).forEach((ogMeta) => ogMeta.remove())

  Array.from(
    document?.querySelectorAll('meta[name^="twitter:"]')
  ).filter(
    (twitterMetta) =>
      !metaPropertiesToExclude.includes(
        Array.from(twitterMetta.attributes).find(
          (attribute) => attribute.name === 'name'
        )?.value || ''
      )
  ).forEach((twitterMetta) => twitterMetta.remove())
}

export const updateTitle = (metaTitle?: string) => {
  if (metaTitle) {
    const title = metaTitle +
    (metaTitle !== RootRoutes[0].meta?.title ? ` | ${RootRoutes[0].meta?.title}` : '')
    document.title = title.length > 59 ? title.substring(0, 57) + '...' : title
  } else {
    document.title = 'Downloadtoepassing Digitaal Vlaanderen'
  }
}

export const updateMetaDescription = (content?: string) => {
  const limitDescription =
    content && content.length > 159
      ? content.substring(0, 158) + '...'
      : content
  updateMetaWithContent('description', limitDescription)
  updateMetaWithContent('og:description', limitDescription, 'property')
}

export const updateMetaKeywords = (content?: string) => {
  updateMetaWithContent('keywords', content)
}
