import CatalogusRoutes from '@/modules/catalogus-module/routes'
import CookiePolicyRoutes from '@/modules/cookie-module/routes'
import DownloadRoutes from '@/modules/downloads-module/routes'
import HomeRoute from '@/modules/home-module/routes'
import LoginRedirectRoutes from '@/modules/login-redirect-module/routes'
import ProductConfigRoutes from '@/modules/product-detail-module/modules/product-config-module/routes'
import ProductDetailRoutes from '@/modules/product-detail-module/routes'
import RootRoutes from '@/modules/routes'
import UserRoutes from '@/modules/user-module/routes'
import vueCompositionApi, {
  computed,
  ComputedRef,
  reactive
} from '@vue/composition-api'
import Vue from 'vue'
import VueRouter, { NavigationGuard, Route, RouteConfig } from 'vue-router'
import { AuthHandler } from './guards/auth-guard'
import { GtagHandler } from './guards/gtag-guard'
import { LatestLocationHandler } from './guards/latest-location-guard'
import { MetaDataHandler } from './guards/meta-data-guard'
import { IRouteMeta } from './interfaces'
import oldRoutes from './old-routes'

export interface IRouteData {
  name: Route['name'];
  params: Route['params'];
  query: Route['query'];
  meta: IRouteMeta | null;
}

Vue.use(vueCompositionApi)

export const updateRouteMeta = ({
  title,
  description,
  keywords,
  breadcrumbValue,
  breadcrumbPath
}: {
  title?: string;
  description?: string;
  keywords?: string[];
  breadcrumbValue?: string;
  breadcrumbPath?:string;
}) => {
  if (RouterInstance.router) {
    if (!RouterInstance.router.currentRoute.meta) {
      RouterInstance.router.currentRoute.meta = {}
    }
    if (RouterInstance.router.currentRoute.meta) {
      if (breadcrumbValue) {
        if (!RouterInstance.router.currentRoute.meta.breadcrumb) {
          RouterInstance.router.currentRoute.meta.breadcrumb = {}
        }
        if (RouterInstance.router.currentRoute.meta.breadcrumb) {
          RouterInstance.router.currentRoute.meta.breadcrumb.dynamicValue =
            breadcrumbValue
          if (breadcrumbPath) RouterInstance.router.currentRoute.meta.breadcrumb.dynamicPath = breadcrumbPath
        }
      }
      RouterInstance.router.currentRoute.meta.title = title
      RouterInstance.router.currentRoute.meta.description = description
      RouterInstance.router.currentRoute.meta.keywords = keywords
    }
  }
}

const routeData: IRouteData = reactive({
  params: {},
  query: {},
  name: null,
  meta: null
})

export const routes: RouteConfig[] = [
  ...ProductConfigRoutes,
  ...ProductDetailRoutes,
  ...CatalogusRoutes,
  ...RootRoutes,
  ...HomeRoute,
  ...DownloadRoutes,
  ...UserRoutes,
  ...LoginRedirectRoutes,
  ...CookiePolicyRoutes,
  ...oldRoutes
]

export const initRouter = ({
  routes,
  guards
}: { routes?: RouteConfig[]; guards?: NavigationGuard[] } = {}) => {
  Vue.use(VueRouter)
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes || [],
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      }
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  })

  router.afterEach((route) => {
    routeData.meta = route.meta || null
    routeData.params = route.params
    routeData.query = route.query
    routeData.name = route.name
  })

  guards?.forEach((guard) => {
    router.beforeEach(guard)
  })

  return router
}

export const defaultGuards = [MetaDataHandler, AuthHandler, LatestLocationHandler, GtagHandler]

export const RouterInstance: {
  router: VueRouter | null;
  data: ComputedRef<IRouteData>;
} = { router: null, data: computed(() => routeData) }
