import { defineStore } from 'pinia'
import Vue from 'vue'
import { ErrorToAlert } from '../alert-handler/error-parser'

export enum EnumAlertIcons {
    InfoCircle = 'info-circle',
    AlertCircle = 'alert-circle',
    Warning = 'warning',
  }

export enum EnumAlertType {
    Success = 'success',
    Warning = 'warning',
    Error = 'Error',
  }

export enum EnumAlertMethod {
    Toast = 'toast',
    Page = 'inpage',
  }

export interface IAlertPage {
    name?: string
    path?: string
  }

export interface IAlert {
    id: string
    pages?: IAlertPage[]
    closable?: boolean
    title?: string
    icon?: EnumAlertIcons
    message?: string
    type?: EnumAlertType
    maxDate?: Date
    method?: EnumAlertMethod
  }

export const useAlertStore = defineStore('alerts', {
  state: (): {alerts: {[key: string]: IAlert}} => ({
    alerts: {}
  }),
  getters: {
    toastAlerts (state) {
      return Object.values(state.alerts).filter(alert => alert.method === EnumAlertMethod.Toast)
    },
    pageAlerts (state) {
      return Object.values(state.alerts).filter(alert => alert.method === EnumAlertMethod.Page)
    }
  },
  actions: {
    addAlert (alert: IAlert) {
      Vue.set(this.alerts, alert.id, alert)
    },
    addError (err: unknown) {
      this.addAlert(ErrorToAlert(err))
    },
    removeAlert (alertId: string) {
      Vue.delete(this.alerts, alertId)
    }
  }
})
