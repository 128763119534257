import { LogLevel } from '../download-client/download-client'

export const DefaultDatasetPageSize = parseInt(process.env.VUE_APP_DEFAULT_DATASET_PAGESIZE || '10')
export const DefaultApiPort = parseInt(process.env.VUE_APP_API_PORT || '7119')
export const DefaultBaseUri = process.env.VUE_APP_BASE_URI || 'localhost'
export const DefaultBaseApiUri = process.env.VUE_APP_BASE_API_URI
export const DefaultApiVersionPath = process.env.VUE_APP_API_VERSION_PATH || ''
export const IsProduction = process.env.VUE_APP_ENV === 'production'
export const ApiLogLevel = process.env.VUE_APP_API_LOG_LEVEL as LogLevel || LogLevel.Error
export const DefaultLoginUrl = `${DefaultBaseUri}:${DefaultApiPort}${DefaultApiVersionPath}/auth/login`
export const DefaultLogoutUrl = `${DefaultBaseUri}:${DefaultApiPort}${DefaultApiVersionPath}/auth/logout`
export const SwitchIdentityUrl = `${DefaultBaseUri}:${DefaultApiPort}${DefaultApiVersionPath}/auth/switch-identity`
export const Environment = (process.env.VUE_APP_ENV || process.env.ASPNETCORE_ENVIRONMENT || process.env.NODE_ENV || 'production').toLowerCase()
export const DefaultHelpUrl = 'https://www.vlaanderen.be/digitaal-vlaanderen/onze-oplossingen/downloadtoepassing/aan-de-slag#toegang-tot-de-downloadtoepassing'
export function GetDefaultLoginUrl () {
  return `${DefaultLoginUrl}?returnUrl=${window.location.href}`
}
export const GtagId = process.env.VUE_APP_GTAG
export const cookieLinkIdentifier = 'https://www.vlaanderen.be/digitaal-vlaanderen/onze-oplossingen/downloadtoepassing/algemene-gebruiksvoorwaarden#cookies'
