import { DlDayJs } from '../dayjs/dayjs'

export const dateToString = (date: Date | undefined | null, format = 'DD/MM/YYYY') => {
  return date ? DlDayJs(date).format(format) : ''
}

export const dateTimeToString = (date: Date | undefined | null, format = 'DD/MM/YYYY HH:mm') => {
  return date ? DlDayJs(date).format(format) : ''
}

export const dateIsEndOfDay = (date: Date | undefined) => {
  if (date) return DlDayJs(date).set('hour', 23).set('minute', 59).set('second', 59).toDate()
  return undefined
}

export const dateIsSameDay = (dateOne:Date, dateTwo:Date) :boolean => {
  return (dateOne.getDay() === dateTwo.getDay() && dateOne.getMonth() === dateTwo.getMonth() && dateOne.getFullYear() === dateTwo.getFullYear())
}

export const dateWithoutTimezone = (date:Date | undefined) => {
  if (date) return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
  return undefined
}
