import { dateWithoutTimezone } from '@/infrastructure/helpers/date-manipulation'
import { computed, defineComponent, onMounted, PropType, reactive, watch } from '@vue/composition-api'

export interface IDlPeriodDate { start: Date[], end: Date[] }

export default defineComponent({
  name: 'dl-period',
  emits: ['date-change'],
  props: {
    minDate: {
      type: Date as PropType<Date>
    },
    maxDate: {
      type: Date as PropType<Date>
    },
    startMinDate: {
      type: Date as PropType<Date>
    },
    startMaxDate: {
      type: Date as PropType<Date>
    },
    endMinDate: {
      type: Date as PropType<Date>
    },
    endMaxDate: {
      type: Date as PropType<Date>
    },
    defaultMinDate: {
      type: Date as PropType<Date>
    },
    defaultMaxDate: {
      type: Date as PropType<Date>
    }
  },
  setup (_props, { emit }) {
    const date: IDlPeriodDate = reactive({
      start: [],
      end: []
    })

    const minStartDate = computed(() => {
      if (_props.startMinDate) return ((_props.startMinDate) as Date).toISOString()
      if (_props.minDate) return ((_props.minDate) as Date).toISOString()
      return null
    })
    const maxStartDate = computed(() => {
      if (_props.startMaxDate) return (dateWithoutTimezone(_props.startMaxDate) as Date).toISOString()
      if (date.end.length >= 1) return (dateWithoutTimezone(date.end[0]) as Date).toISOString()
      return null
    })
    const minEndDate = computed(() => {
      if (_props.endMinDate) return ((_props.endMinDate) as Date).toISOString()
      if (date.start.length >= 1) return ((date.start[0]) as Date).toISOString()
      return null
    })
    const maxEndDate = computed(() => {
      if (_props.endMaxDate) return (dateWithoutTimezone(_props.endMaxDate) as Date).toISOString()
      if (_props.maxDate) return (dateWithoutTimezone(_props.maxDate) as Date).toISOString()
      return null
    })

    onMounted(() => {
      date.start = [dateWithoutTimezone(_props.defaultMinDate) as Date]
      date.end = [dateWithoutTimezone(_props.defaultMaxDate) as Date]
    })

    watch(date, () => {
      emit('date-change', date)
    }, { deep: true })

    return { date, maxStartDate, minEndDate, minStartDate, maxEndDate }
  }
})
