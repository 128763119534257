import Vue from 'vue'
import * as WebComponents from '@govflanders/vl-ui-vue-components'
import { surfaceOfWktInKm, WkTInsideVlaanderen, singlePolygonWkt, isValidPolygon } from '../helpers/map'
import { required } from 'vee-validate/dist/rules'
import dayjs from 'dayjs'
import { dateToString } from '../helpers/date-manipulation'

export default {
  install (vue: typeof Vue): void {
    vue.use(WebComponents)
    vue.directive('vl-tooltip', WebComponents.VlTooltip)
    vue.directive('vl-modal-toggle', WebComponents.VlModalToggle)

    WebComponents.extend('wkt-size-smaller', {
      validate: (value: string, args: any) => surfaceOfWktInKm(value) < args.surfaceArea,
      params: ['surfaceArea'],
      message: 'De versnijding moet kleiner zijn dan {surfaceArea} km²'
    })

    WebComponents.extend('wkt-size-larger', {
      validate: (value: string, args: any) => surfaceOfWktInKm(value) > args.surfaceArea,
      params: ['surfaceArea'],
      message: 'De versnijding moet groter zijn dan {surfaceArea} km²'
    })

    WebComponents.extend('wkt-required', {
      ...required,
      message: 'Gelieve een versnijding te tekenen of te importeren op de kaart'
    })

    WebComponents.extend('wkt-single-polygon', {
      validate: (value: string) => singlePolygonWkt(value),
      message: 'Uw bestand mag slechts één polygoon bevatten'
    })

    WebComponents.extend('wkt-in-vlaanderen', {
      validate: (value: string) => WkTInsideVlaanderen(value),
      message: 'De versnijding moet zich binnen Vlaanderen bevinden'
    })

    WebComponents.extend('is-valid-polygon', {
      validate: (value: string) => isValidPolygon(value),
      message: 'De huidige polygoon is geen geldige polygoon'
    })

    WebComponents.extend('before', {
      validate: (value: Date, { before }: { before: Date }) => before ? dayjs(value).isBefore(before) : true,
      params: ['before'],
      message: (fieldName: string, params: { before: Date }) => `'${fieldName}' komt na ${dateToString(params.before)}`
    })

    WebComponents.extend('after', {
      validate: (value: Date, { after }: { after: Date }) => after ? dayjs(value).isAfter(after) : true,
      params: ['after'],
      message: (fieldName: string, params: { after: Date }) => `'${fieldName}' komt voor ${dateToString(params.after)}`
    })
  }
}
