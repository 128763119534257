import { IRouteConfig } from '@/infrastructure/router/interfaces'
import { RouteHome } from '@/modules/home-module/routes'

const CookieModuleComponent = () =>
  import('../cookie-module.vue')

export const RouteCookieModule: IRouteConfig = {
  path: '/cookie-policy',
  name: 'Cookie policy',
  component: CookieModuleComponent,
  meta: {
    title: 'Cookie policy',
    breadcrumb: {
      parent: RouteHome
    }
  }
}

export const CookiePolicyRoutes = [RouteCookieModule]

export default CookiePolicyRoutes
