import { IRouteConfig } from '@/infrastructure/router/interfaces'
import { RootRoutes } from '@/modules/routes'

const DownloadsComponent = () => import('@/modules/downloads-module/downloads-module.vue')

export const RouteDownloads:IRouteConfig = {
  path: '/downloads',
  name: 'Downloads',
  component: DownloadsComponent,
  meta: {
    title: 'Downloads',
    description: 'Overzicht van alle gedownloade documenten',
    tags: [
      { key: 'og:image', attribute: 'property', content: 'https://assets.vlaanderen.be/image/upload/c_fill,h_630,q_75,w_1200/TWIT_card_algemeen_aw4mra' },
      { key: 'twitter:card', attribute: 'name', content: 'summary' }
    ],
    breadcrumb: {
      parent: RootRoutes[0]
    }
  }
}

export const RouteDownloadsPolling:IRouteConfig = {
  path: '/downloads/:id',
  name: 'Downloads',
  component: DownloadsComponent,
  meta: {
    title: 'Downloads',
    description: 'Overzicht van alle gedownloade documenten',
    tags: [
      { key: 'og:image', attribute: 'property', content: 'https://assets.vlaanderen.be/image/upload/c_fill,h_630,q_75,w_1200/TWIT_card_algemeen_aw4mra' },
      { key: 'twitter:card', attribute: 'name', content: 'summary' }
    ],
    breadcrumb: {
      parent: RootRoutes[0]
    }
  }
}

const DownloadRoutes = [RouteDownloads, RouteDownloadsPolling]
export default DownloadRoutes
