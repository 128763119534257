import { cookieKey } from '@/infrastructure/adapters/constants'
import { defineComponent, onMounted, ref } from '@vue/composition-api'

export enum EnumCookieOptions{
    Required = 'required',
    Analytics = 'analytics'
}

export enum EnumCookieResponse{
    All,
    Noodzakelijke,
    Keuze
}

export interface ICookieStorage{
    key: EnumCookieOptions,
    value: boolean
}

export default defineComponent({
  name: 'dl-cookie-consent-module',
  setup (_props, { emit }) {
    const options = ref([{
      value: EnumCookieOptions.Required,
      disabled: true,
      checked: true,
      label: 'Noodzakelijke cookies',
      description: 'Deze cookies zijn vereist om de website goed te laten werken en zorgen ervoor dat uw cookievoorkeuren worden bewaard.'
    }, {
      value: EnumCookieOptions.Analytics,
      disabled: false,
      checked: false,
      label: 'Analytische cookies',
      description: 'Deze cookies meten het aantal bezoekers op de website en geven een algemeen beeld van uw bezoek. Alle verzamelde gegevens zijn anoniem.'
    }])

    function consentAll () {
      const opts = options.value.map(x => ({
        key: x.value,
        value: true
      }) as ICookieStorage)
      localStorage.setItem(cookieKey, JSON.stringify(opts))
      emit('submit', true)
    }

    function consentRequired () {
      const opts = options.value.map(x => ({
        key: x.value,
        value: x.value === EnumCookieOptions.Required
      }) as ICookieStorage)
      localStorage.setItem(cookieKey, JSON.stringify(opts))
      emit('submit', true)
    }

    function consent () {
      // eslint-disable-next-line prefer-const
      let opts = options.value.map(x => ({
        key: x.value,
        value: x.checked
      }) as ICookieStorage)
      localStorage.setItem(cookieKey, JSON.stringify(opts))
      emit('submit', true)
    }

    onMounted(() => {
      if (localStorage.getItem(cookieKey) !== null) {
        const storageOptions = JSON.parse(localStorage.getItem(cookieKey) as string) as ICookieStorage[]
        options.value.forEach((x) => {
          const temp = storageOptions.find(y => y.key === x.value)
          x.checked = temp ? temp.value : false
        })
      }
    })

    return {
      options,
      consent,
      consentAll,
      consentRequired,
      EnumCookieResponse
    }
  }
})
