import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { FolderNode } from '../dl-folder-node-component/dl-folder-node-component'

export default defineComponent({
  name: 'dl-folder-accordion-component',
  props: {
    node: {
      type: Object as PropType<FolderNode>,
      required: true
    }
  },
  setup (props, { emit }) {
    const hidden = ref(false)
    const hiddenIcon = computed(() => hidden.value ? 'nav-up' : 'nav-down')

    function toggleHidden () {
      hidden.value = !hidden.value
    }

    function check (file:unknown) {
      if (props.node) {
        emit('check', props.node.key !== null && props.node.key !== file ? props.node.key + '/' + (file as string) : (file as string))
      }
    }

    return { check, hidden, toggleHidden, hiddenIcon }
  }
})
