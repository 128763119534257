import { IRouteConfig } from '@/infrastructure/router/interfaces'
import PageNotFound from '../page-not-found.vue'

export const RoutePageNotFound: IRouteConfig = {
  path: '*',
  name: 'Pagina niet gevonden',
  component: PageNotFound
}

export const PageNotFoundRoute = [RoutePageNotFound]

export default PageNotFoundRoute
