import { IRouteConfig } from '@/infrastructure/router/interfaces'
import { RootRoutes } from '@/modules/routes'

export const RouteCatalogus: IRouteConfig = {
  path: '/catalogus',
  name: 'Catalogus',
  component: () => import('@/modules/catalogus-module/catalogus-module.vue'),
  meta: {
    title: 'Catalogus',
    description:
      'De catalogus van te downloaden geografische producten, aangeboden door Digitaal Vlaanderen.',
    tags: [
      {
        key: 'og:image',
        attribute: 'property',
        content:
          'https://assets.vlaanderen.be/image/upload/c_fill,h_630,q_75,w_1200/TWIT_card_algemeen_aw4mra'
      },
      { key: 'twitter:card', attribute: 'name', content: 'summary' }
    ],
    breadcrumb: {
      parent: RootRoutes[0]
    }
  }
}

export const CatalogusRoutes = [RouteCatalogus]

export default CatalogusRoutes
