import { EnumAlertMethod, EnumAlertType, useAlertStore } from '../store/alert'
import { ErrorToAlert } from './error-parser'

import { v4 as uuidv4 } from 'uuid'

export const GenerateErrorToaster = (err: unknown, closable = true) => {
  useAlertStore().addAlert(ErrorToAlert(err, EnumAlertMethod.Toast, closable))
}

export const GenerateSuccessToaster = (message: string) => {
  useAlertStore().addAlert({
    id: uuidv4(),
    closable: true,
    method: EnumAlertMethod.Toast,
    type: EnumAlertType.Success,
    message
  })
}
