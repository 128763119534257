import { DefaultApiPort, DefaultBaseUri } from '../adapters/environment-variables'
import { DownloadClient } from './download-client'
import axios from 'axios'

export const DownloadClientFactory = () => {
  const dlClient = axios.create({
    withCredentials: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
  return new DownloadClient(`${DefaultBaseUri}:${DefaultApiPort}`, dlClient)
}

export const DownloadClientInstance = DownloadClientFactory()
