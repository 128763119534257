import { IRouteConfig } from '@/infrastructure/router/interfaces'

export const RouteHome: IRouteConfig = {
  path: '/',
  name: 'Downloadtoepassing',
  component: () =>
    import('@/modules/home-module/home-module.vue'),
  meta: {
    title: 'Downloadtoepassing Digitaal Vlaanderen',
    description: 'Download hier geografische producten, aangeboden door Digitaal Vlaanderen.',
    tags: [
      { key: 'og:image', attribute: 'property', content: 'https://assets.vlaanderen.be/image/upload/c_fill,h_630,q_75,w_1200/TWIT_card_algemeen_aw4mra' },
      { key: 'twitter:card', attribute: 'name', content: 'summary' }
    ]
  }
}

export const HomeRoute = [RouteHome]

export default HomeRoute
