import { RouterInstance } from '@/infrastructure/router'
import { EnumAnalyticsHeaderPageActions, EnumAnalyticsPage, useAnalyticsStore } from '@/infrastructure/store/analytics'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-header',
  setup () {
    const { logAnalyticsEvent } = useAnalyticsStore()
    function goToDownloads () {
      logAnalyticsEvent(EnumAnalyticsHeaderPageActions.MyDownloads, EnumAnalyticsPage.Header)
      RouterInstance.router?.push('/downloads')
    }

    function goToHome () {
      RouterInstance.router?.push({ path: '/' })
    }

    return {
      goToDownloads,
      goToHome
    }
  }
})
