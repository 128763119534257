import { IRouteConfig } from '@/infrastructure/router/interfaces'
import { RouteHome } from '@/modules/home-module/routes'

const UserComponent = () =>
  import('../user-module.vue')

export const RouteUser: IRouteConfig = {
  path: '/gebruiker',
  name: 'Gebruiker',
  component: UserComponent,
  meta: {
    title: 'Gebruiker gegevens',
    breadcrumb: {
      parent: RouteHome
    }
  }
}

export const UserRoutes = [RouteUser]

export default UserRoutes
