import { ApiLogLevel, IsProduction } from '../adapters/environment-variables'
import { ErrorToString } from '../alert-handler/error-parser'
import { DownloadClientInstance } from '../download-client/dl-client'
import { LogLevel } from '../download-client/download-client'
export enum EShowLogMessage{
  Toast
}

export const DlLogger = (loggingLevel: LogLevel, fileName: string, functionName: string | null, logMessage: unknown) => {
  try {
    logMessage = ErrorToString(logMessage)
    const logLevelOrder = [LogLevel.None, LogLevel.Trace, LogLevel.Debug, LogLevel.Information, LogLevel.Warning, LogLevel.Error, LogLevel.Critical]
    const message = `[${fileName}]${functionName ? `[${functionName}]` : ''} ${logMessage}`
    if (!IsProduction) {
      switch (loggingLevel) {
        case LogLevel.Critical:
        case LogLevel.Error:
          console.error(message)
          break
        case LogLevel.Warning:
          console.warn(message)
          break
        case LogLevel.None:
          console.log(message)
          break
        case LogLevel.Information:
          console.info(message)
          break
        case LogLevel.Debug:
        case LogLevel.Trace:
          console.debug(message)
          break
      }
    }

    if (logLevelOrder.indexOf(loggingLevel) >= logLevelOrder.indexOf(ApiLogLevel)) {
      DownloadClientInstance.logging_Log({ loggingLevel, message })
    }
  } catch (err) {
    // no action
  }
  return { loggingLevel, logMessage }
}
